<template>
  <div class="todo">
    <!-- <h2>INTEGRANDE NO DESENVOLVIMENTO</h2> -->
    <div v-for="(item, i) in integrantes" :key="i" class="mb-3">
      <v-card class="card" max-width="100%" outlined>
        <v-avatar rounded="0" size="150">
          <img :src="item.foto" :alt="item.nome" />
        </v-avatar>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ item.nome }}
            </v-list-item-title>
            <v-list-item-subtitle class="mb-4">
              {{ item.info }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ item.faculdade }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ item.universidade }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </div>
  </div>
</template>

<script>
import integrantesBD from "../assets/db/integrantes.json";
export default {
  name: "Sobre",
  data: () => ({
    integrantes: integrantesBD,
  }),
  methods: {},
};
</script>

<style scoped>
.todo {
  width: 100%;
  min-height: calc(100vh - 64px);
  padding: 15px 100px;
}
.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
</style>
