<template>
  <v-app id="fundo">
    <v-app-bar app color="#00636B" dark height="64">
      <v-container id="max_nav_bar">
        <v-row>
          <div class="d-flex align-center">
            <v-img
              alt="Logo HistoPat"
              src="./assets/img/logo_nav.svg"
              transition="scale-transition"
              width="140"
            />
          </div>

          <v-spacer></v-spacer>
          <div id="menu">
            <v-btn text tile height="64" to="/"> HOME </v-btn>
            <v-btn text tile height="64" to="/histologia"> HISTOLOGIA </v-btn>
            <v-btn text tile height="64" to="/patologia"> PATOLOGIA </v-btn>
            <v-btn text tile height="64" to="/microscopia"> MICROSCOPIA </v-btn>
            <v-menu>
              <template v-slot:activator="{ attrs, on }">
                <v-btn plain icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="item in mais_menu" :key="item.title" link>
                  <!-- <v-list-item-title v-text="item.title"> </v-list-item-title> -->
                  <v-btn text class="btn" color="#009687" :to="item.link">
                    {{ item.title }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <div id="menu_sanduiche">
            <v-menu>
              <template v-slot:activator="{ attrs, on }">
                <v-app-bar-nav-icon v-bind="attrs" v-on="on">
                </v-app-bar-nav-icon>
              </template>
              <v-list>
                <v-list-item v-for="item in menu" :key="item.title" link>
                  <!-- <v-list-item-title v-text="item.title"> </v-list-item-title> -->
                  <v-btn text class="btn" color="#009687" :to="item.link">
                    {{ item.title }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-container id="max_conteudo" class="white">
      <v-main>
        <router-view />
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    menu: [
      { title: "HOME", link: "/" },
      { title: "HISTOLOGIA", link: "/histologia" },
      { title: "PATOLOGIA", link: "/patologia" },
      { title: "MICROSCOPIA", link: "/microscopia" },
      { title: "SOBRE", link: "/sobre" },
      { title: "ÁREA RESTRITA", link: "/restrito" },
    ],
    mais_menu: [
      { title: "SOBRE", link: "/sobre" },
      { title: "ÁREA RESTRITA", link: "/restrito" },
    ],
  }),
  methods: {},
};
</script>

<style>
#fundo {
  background-color: #ebebeb;
}

#max_nav_bar {
  max-width: 1200px;
  padding: 40px;
}
#max_conteudo {
  max-width: 1200px;
  padding: 0px;
}

#menu {
  display: inline;
}

#menu_sanduiche {
  display: none;
}

@media only screen and (max-width: 1000px) {
  #menu {
    display: none;
  }

  #menu_sanduiche {
    display: inline;
  }
}
</style>
