<template>
  <div id="todo">
    <div id="lado_A">
      <h1>PATOLOGIA</h1>
      <br />
      <p id="parag_01">
        Para Kumar, Abbas e Aster (2013), “Patologia é literalmente o estudo
        (logos) do sofrimento (páthos). Ela envolve a investigação das causas da
        doença e as alterações associadas em nível de células, tecidos e órgãos,
        que resultam em sinais e sintomas presentes no paciente”.
      </p>
      <p id="parag_01">
        Ainda conforme Kumar, Abbas e Aster (2013), existem dois termos
        importantes que os estudantes encontrarão durante o seu estudo de
        medicina e patologia:
      </p>
      <li>
        A etiologia é a origem da doença, que inclui as causas fundamentais e os
        fatores modificadores.
      </li>
      <li>A patogenia refere-se às etapas do desenvolvimento da doença.</li>
      <br />
      <p id="parag_02">
        KUMAR, V.; ABBAS, A.; ASTER, J. Robbins – Patologia Básica. 9. ed. Rio
        de Janeiro: Elsevier, 2013. 928 p.
      </p>
      <p id="parag_01">
        De acordo com a Sociedade Brasileira de Patologia (2016), assim como o
        termo, a própria patologia tem suas raízes na terra dos filósofos.
        Embora não seja possível precisar um marco inicial, a origem da
        especialidade remonta à Grécia Antiga e se funde à origem da própria
        medicina ocidental como a conhecemos.
      </p>
      <p id="parag_02">
        PATOLOGIA, S. B. Três mil anos de estudo: a história da patologia. 2016.
        Não paginado. Disponível em: link Acesso em: 18 mar. 2021.
      </p>
    </div>

    <div id="lado_B">
      <div id="emBranco"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Patologia",
};
</script>

<style scoped>
h1 {
  font-size: 50px;
}
#todo {
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: row;
  background-image: url("../assets/img/medicaP@2x.png");
  background-position: bottom right;
  background-size: 60%;
}

#lado_A {
  width: 65%;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 25px;
  color: #00636b;
  /* background-color: aliceblue; */
}
#lado_B {
  width: 35%;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 55px;
  text-align: center;
  color: #00636b;
  /* background-color: aquamarine; */
}
.btn {
  width: 100%;
  color: #fff;
}
#parag_01 {
  width: 100%;
  text-align: justify;
  text-justify: distribute;
}
#parag_02 {
  width: 100%;
  font-size: 8pt;
  text-align: justify;
  text-justify: distribute;
}

#medica {
  width: 100%;
}

#emBranco {
  height: 180px;
}

@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 25px;
  }
  #todo {
    flex-direction: column;
    background-size: 100%;
  }
  #lado_A {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #lado_B {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .btn {
    width: 100%;
  }
  #parag_01 {
    width: 100%;
  }
  #parag_02 {
    width: 100%;
  }
}
</style>
