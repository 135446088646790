<template>
  <div id="todo">
    <div id="lado_A">
      <h1>HISTOLOGIA</h1>
      <br />
      <p id="parag_01">
        Segundo Junqueira e Carneiro (2013), Histologia “é o estudo das células
        e dos tecidos do corpo e de como essas estruturas se organizam para
        constituir os órgãos.
      </p>
      <p id="parag_01">
        Em razão das pequenas dimensões das células, seu estudo é realizado com
        auxílio de microscópios”.
      </p>
      <p id="parag_01">
        Conforme Ross e Pawlina (2016), “Histologia [Gr., ιοτός, histos =
        tecido, λογία, logia = ciência], também chamada de anatomia
        microscópica, é o estudo científico de estruturas microscópicas de
        tecidos e órgãos do corpo.
      </p>
      <p id="parag_01">
        A histologia moderna não é apenas uma ciência descritiva, mas também
        inclui muitos aspectos de biologia molecular e celular, que ajudam a
        descrever a organização e a função das células”.
      </p>
      <br />
      <p id="parag_02">
        JUNQUEIRA L.C.; CARNEIRO J. Histologia Básica. 12ª Ed. Rio de Janeiro:
        Guanabara Koogan, 2013. 538 p
      </p>
      <p id="parag_02">
        ROSS M.H., PAWLINA W. Histologia: Texto e Atlas: Correlações com
        Biologia Celular e Molecular. 7ª Ed. Rio de Janeiro: Guanabara Koogan,
        2016.
      </p>
    </div>

    <div id="lado_B">
      <h3>BANCO DE LAMINAS</h3>
      <v-btn class="btn" color="#009687" dark to="/laminas">
        ACESSE AQUI
      </v-btn>
      <div id="emBranco"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Histologia",
};
</script>

<style scoped>
h1 {
  font-size: 50px;
}
#todo {
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: row;
  background-image: url("../assets/img/medicaH@2x.png");
  background-position: bottom right;
  background-size: 60%;
}

#lado_A {
  width: 65%;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 25px;
  color: #00636b;
  /* background-color: aliceblue; */
}
#lado_B {
  width: 35%;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 55px;
  text-align: center;
  color: #00636b;
  /* background-color: aquamarine; */
}
.btn {
  width: 100%;
  color: #fff;
}
#parag_01 {
  width: 100%;
  text-align: justify;
  text-justify: distribute;
}
#parag_02 {
  width: 100%;
  font-size: 8pt;
  text-align: justify;
  text-justify: distribute;
}

#medica {
  width: 100%;
}

#emBranco {
  height: 280px;
}

@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 25px;
  }
  #todo {
    flex-direction: column;
    background-size: 100%;
  }
  #lado_A {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #lado_B {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .btn {
    width: 100%;
  }
  #parag_01 {
    width: 100%;
  }
  #parag_02 {
    width: 100%;
  }
}
</style>
