<template>
  <div id="todo">
    <div id="lado_A">
      <h1>LAMINAS</h1>
      <template>
        <v-expansion-panels
          full-width
          v-model="panel"
          :disabled="disabled"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6" color="#00636b">
                      FILTRO
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-header>
            <!-- <v-divider></v-divider> -->
            <v-expansion-panel-content>
              <v-list>
                <v-list-item-group
                  multiple
                  v-model="grupo"
                  active-class=""
                  color="#00636b"
                  v-for="(item, i) in categorias"
                  :key="i"
                >
                  <div>
                    <v-list-item :value="item.categoria">
                      <template v-slot:default="{ active }">
                        <v-checkbox
                          :input-value="active"
                          color="#00636b"
                        ></v-checkbox>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.categoria
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.info
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </div>

    <div id="lado_B">
      <!-- <h1>LAMINAS</h1> -->
      <div v-for="(item, i) in imagens" :key="i">
        <v-card
          class="mx-auto"
          max-width="100%"
          outlined
          v-if="filterGrupo(item.grupo)"
          id="espacoVcard"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-0">{{ item.grupo }}</div>
              <v-list-item-subtitle class="mb-4">
                {{ item.tecido }}
              </v-list-item-subtitle>
              <v-list-item-title class="text-h5 mb-1">
                {{ item.nome }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.resumo }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-card-actions>
              <v-col cols="auto">
                <v-dialog transition="dialog-bottom-transition" max-width="900">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn fab v-bind="attrs" v-on="on" id="sizeBT">
                      <v-avatar size="90">
                        <img :src="item.src" :alt="item.nome" />
                      </v-avatar>
                    </v-btn>
                  </template>

                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="#00636b" dark>
                        {{ item.nome }} - {{ item.resumo }}
                      </v-toolbar>
                      <v-carousel
                        :show-arrows="true"
                        hide-delimiters
                        height="100%"
                      >
                        <v-carousel-item v-for="(img, i) in item.imgs" :key="i">
                          <v-sheet height="100%" tile>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center"
                            >
                              <div id="configZoon">
                                <div id="sombra">
                                  <v-avatar size="400" id="avatarZoonG">
                                    <img :src="img" :alt="item.nome" />
                                  </v-avatar>
                                  <v-avatar size="250" id="avatarZoonP">
                                    <img :src="img" :alt="item.nome" />
                                  </v-avatar>
                                </div>
                                <br />
                              </div>
                              <div id="textoZoon">
                                Zoon Digital: {{ i === 0 ? 1 : i * 2 }}x
                              </div>
                            </v-row>
                          </v-sheet>
                        </v-carousel-item>
                      </v-carousel>
                      <div id="conteudoZoom">
                        <p id="parag_01">
                          {{ item.info }}
                        </p>
                      </div>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">Sair</v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                  <!-- ------------- -->
                </v-dialog>
              </v-col>
            </v-card-actions>
          </v-list-item>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import laminasBD from "../assets/db/laminas.json";
import categoriaBD from "../assets/db/categoria.json";
export default {
  name: "Laminas",
  data: () => ({
    panel: [0],
    readonly: false,
    grupo: [],
    imagens: laminasBD,
    categorias: categoriaBD,
  }),
  methods: {
    filterGrupo(item) {
      let filterGrupo = this.grupo.filter((itemGrupo) => itemGrupo === item);
      return filterGrupo.length > 0
        ? true
        : this.grupo.length === 0
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
#configZoon {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

#sombra {
  background-color: rgb(173, 173, 173);
  box-shadow: 0 0 1em rgb(173, 173, 173);
  border-radius: 100%;
}

#sizeBT {
  width: 94px;
  height: 94px;
}

#avatarZoonG {
  display: flex;
}
#avatarZoonP {
  display: none;
}

#textoZoon {
  width: 100%;
  height: 61px;
  text-align: center;
  line-height: 48px;
  font-family: "roboto";
  font-size: 20px;
  font-weight: 500;
  color: gray;
}

#conteudoZoom {
  width: 100%;
  padding: 25px;
}

#espacoVcard {
  margin-top: 10px;
}

h1 {
  font-size: 40px;
  text-align: center;
  padding: 20px;
}

#tituloLaminas {
  font-size: 50px;
  margin-left: 10px;
}

#todo {
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: row;
}

#lado_A {
  width: 35%;
  padding-left: 55px;
  padding-right: 0px;
  padding-top: 25px;
  color: #00636b;
  /* background-color: aliceblue; */
}
#lado_B {
  width: 65%;
  height: calc(100vh - 64px);
  padding-left: 25px;
  padding-right: 55px;
  padding-top: 25px;
  padding-bottom: 15px;
  color: #00636b;
  overflow: auto;
  /* background-color: aquamarine; */
}

#parag_01 {
  width: 100%;
  text-align: justify;
  text-justify: distribute;
}
#parag_02 {
  width: 100%;
  font-size: 8pt;
  text-align: justify;
  text-justify: distribute;
}

@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 25px;
  }
  #todo {
    flex-direction: column;
    background-size: 100%;
  }
  #lado_A {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #lado_B {
    width: 100%;
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
    overflow: none;
  }
  #conteudo {
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-bottom: 10px;
    overflow: none;
  }
  #parag_01 {
    width: 100%;
  }
  #parag_02 {
    width: 100%;
  }
  #avatarZoonG {
    display: none;
  }
  #avatarZoonP {
    display: flex;
  }
}
</style>
