<template>
  <div id="todo">
    <div id="lado_A">
      <h1>MICROSCOPIA</h1>
      <br />
      <p id="parag_01">
        A criação do microscópio revolucionou completamente a maneira do homem
        ver o mundo. A microscopia possibilitou a observação, exploração e
        estudos de diversas áreas até então desconhecidas, revolucionando o
        conhecimento científico. Causas invisíveis, motivos de vária afecções
        que acometiam a população, muitas vezes consideradas sobrenaturais,
        passaram a ser observadas. Desta forma, o conhecimento de fenômenos
        patológicos e os seres que os originavam pôde ser alcançado.
      </p>
      <p id="parag_01">
        A palavra microscópio tem sua origem nos termos mikrós (do grego,
        pequeno) e scoppéoo (do grego observar, ver através). Muitos ainda
        discutem quem teria inventado esse equipamento. Mas para chegar na forma
        que conhecemos hoje foi preciso um processo longo, que começou com algo
        simples, as lentes.
      </p>

      <br />

      <h2>MICROSCÓPIO</h2>
      <br />
      <div id="microscopioID">
        <v-img
          alt="Microscópio ID"
          src="../assets/img/microscopioID@2x.png"
          transition="scale-transition"
        />

        <div
          v-for="(item, i) in menu"
          :key="i"
          :id="item.id"
          class="text-center"
        >
          <v-menu
            v-model="item.menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                text
                color="#00636B"
                v-bind="attrs"
                v-on="on"
                id="fonteBT"
              >
                {{ item.nome }}
                <!-- <v-icon> mdi-plus </v-icon> -->
              </v-btn>
            </template>

            <v-card width="400px">
              <v-list>
                <v-card-title>{{ item.nome }}</v-card-title>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <v-list-item class="text-justify">
                  {{ item.txt }}
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </div>
      <p id="parag_02">Referências</p>
      <p id="parag_02">
        KASV. Disponível em:
        https://kasvi.com.br/microscopio-microscopia-historia-evolucao/. Acesso
        em: 19 março 2021.
      </p>
      <p id="parag_02">
        BIOMEDICINA BRASIL. O microscópio óptico e seus componentes. Disponível
        em:
        http://biomedicinabrasil.com.br/microscopia/o-microscopio-optico-e-seus-componentes/.
        Acesso em: 19 março 2021.
      </p>
      <p id="parag_02">
        BLOG SP LABOR. Microscópio Óptico Binocular – Conheça Suas Partes,
        Manuseio e Limpeza. Disponível em:
        https://www.splabor.com.br/blog/microscopio-estereoscopio/aprendendo-mais-aspectos-importantes-sobre-microscopio-optico-denominacao-suas-respectivas-partes-manuseio-e-limpeza/.
        Acesso em: 19 março 2021.
      </p>
      <p id="parag_02">
        EXPERIMENTOTECA. Partes do Microscópio Óptico. Disponível em:
        http://experimentoteca.com/biologia/partes-microscopio-optico/. Acesso
        em: 19 março 2021.
      </p>
      <br />
      <h2>PREPARAÇÃO DE LAMINAS</h2>
      <br />
      <v-expansion-panels focusable>
        <v-expansion-panel>
          <v-expansion-panel-header color="#E0EFEF" id="laminas">
            <b>FIXAÇÃO</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content id="laminas">
            <br />
            <p id="parag_01">
              Ao se remover qualquer material de um organismo ocorre
              naturalmente um processo de autólise (autodigestão). A fixação
              evita essa morte celular natural e impede a proliferação de
              microrganismos, preservando a morfologia do tecido.
            </p>

            <p id="parag_01">
              Dessa forma, a fixação utiliza processos físicos ou químicos para
              imobilizar as substâncias constituintes das células e dos tecidos.
              Os agentes fixadores mais utilizados são o
              <b>formol tamponado</b> e o <b>líquido de Bouin</b>.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header color="#E0EFEF" id="laminas">
            <b>DESIDRATAÇÃO</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content id="laminas">
            <br />
            <p id="parag_01">
              Vários métodos são utilizados, porém o mais comum compreende uma
              série de soluções alcoólicas em concentrações diferentes, chegando
              até o álcool 100%.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header color="#E0EFEF" id="laminas">
            <b>CLARIFICAÇÃO OU DIAFANIZAÇÃO</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content id="laminas">
            <br />
            <p id="parag_01">
              Essa etapa remove totalmente o álcool, preparando o espécime para
              a etapa seguinte. Para remover o álcool e preparar o tecido para a
              penetração da parafina, utiliza-se o xilol. Conforme o xilol
              penetra o tecido, em substituição ao álcool, o material se torna
              mais claro, transparente. Por essa razão, é denominada de
              clarificação.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header color="#E0EFEF" id="laminas">
            <b>INCLUSÃO</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content id="laminas">
            <br />
            <p id="parag_01">
              Mesmo após a fixação e a desidratação, as amostras de tecido são
              ainda muito frágeis. Acontece então a impregnação do tecido com
              uma substância de consistência firme. Assim o tecido é endurecido,
              o que facilita o corte em camadas finas. A parafina é a mais
              utilizada neste procedimento.
            </p>
            <p id="parag_01">
              Os cassetes histológicos são utilizados durante todos os
              processos, até mesmo na inclusão. Eles permitem escrever o número
              de registro do material, identificando o espécime. Também são
              importantes para a microtomia, pois podem ser adaptados ao
              micrótomo.
            </p>
            <v-img
              alt="Case Histológico"
              src="../assets/img/case_histologico.png"
              transition="scale-transition"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header color="#E0EFEF" id="laminas">
            <b>MICROTOMIA</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content id="laminas">
            <br />
            <p id="parag_01">
              Depois de endurecido, o bloco de parafina deve ser cortado em
              seções extremamente finas, que permitam a visualização do tecido
              ao microscópio. Para isso, é utilizado o equipamento de precisão
              micrótomo, que alcança a espessura dos cortes de 5 a 15 μm
              (micrômetros).
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ------------- -->
        <v-expansion-panel>
          <v-expansion-panel-header color="#E0EFEF" id="laminas">
            <b>COLORAÇÃO</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content id="laminas">
            <br />
            <p id="parag_01">
              Como as seções de parafina são incolores, os espécimes não estão
              ainda adequados para exame com microscópio de luz. São então
              corados para possibilitar a análise. A parafina deve ser
              dissolvida e removida. Em seguida os tecidos na lâmina são
              reidratados por meio de uma série de soluções de álcool em
              concentrações decrescentes.
            </p>
            <p id="parag_01">
              Os cortes de tecido podem então ser corados com hematoxilina. Por
              sua natureza básica, a hematoxilina vai corar os ácidos nucleicos
              dos núcleos. Em seguida, os cortes são lavados em e corados pela
              eosina, um corante de natureza ácida e que irá corar os
              componentes básicos predominantes no citoplasma das células. A
              hematoxilina é um corante de cor azul-púrpura, ou seja, o que você
              observar na lâmina que tenha essa cor, você já sabe que é uma
              substância de caráter ácido, sendo, portanto, basófila, pois foi
              atraída pela hematoxilina. E a eosina é um corante vermelho. Sendo
              assim, tudo aquilo que você observar na lâmina que seja dessa cor,
              é uma substância básica, e que foi atraída pela eosina que é
              ácida, sendo então uma substância acidófila.
            </p>
            <v-card max-width="400" class="mx-auto">
              <v-toolbar color="purple" dark> </v-toolbar>
              <v-list three-line>
                <template>
                  <v-card-title>Hematoxilina</v-card-title>
                  <v-card-subtitle>Corante - (básico)</v-card-subtitle>
                  <v-divider></v-divider>
                  <v-card-title>Ácida</v-card-title>
                  <v-card-subtitle>Substância Corada</v-card-subtitle>
                  <v-card-title>Basófila</v-card-title>
                  <v-card-subtitle
                    >Caracteristica por afinidade</v-card-subtitle
                  >
                  <v-card-title>Azul-Púrpura</v-card-title>
                  <v-card-subtitle>Coloração</v-card-subtitle>
                </template>
              </v-list>
            </v-card>
            <br />
            <v-card max-width="400" class="mx-auto">
              <v-toolbar color="red" dark> </v-toolbar>
              <v-list three-line>
                <template>
                  <v-card-title>Eosina</v-card-title>
                  <v-card-subtitle>Corante - (ácida)</v-card-subtitle>
                  <v-divider></v-divider>
                  <v-card-title>Básica</v-card-title>
                  <v-card-subtitle>Substância Corada</v-card-subtitle>
                  <v-card-title>Acidófila</v-card-title>
                  <v-card-subtitle
                    >Caracteristica por afinidade</v-card-subtitle
                  >
                  <v-card-title>Vermelho</v-card-title>
                  <v-card-subtitle>Coloração</v-card-subtitle>
                </template>
              </v-list>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ------------- -->
      </v-expansion-panels>
      <br /><br />
      <p id="parag_02">Referências</p>
      <p id="parag_02">
        KASVI. Técnicas histopatológicas: Preparação de tecidos. Disponível em:
        https://kasvi.com.br/histopatologica-tecnica-tecido-celular/. Acesso em:
        19 março 2021.
      </p>
      <p id="parag_02">
        KASVI. Técnicas histopatológicas: Preparação de tecidos. Disponível em:
        https://kasvi.com.br/histopatologica-tecnica-tecido-celular/. Acesso em:
        19 março 2021.
      </p>
      <p id="parag_02">
        JALEKO. Hematoxilina-Eosina e outras colorações histológicas e
        microbianas. Disponível em:
        https://blog.jaleko.com.br/hematoxilina-eosina-e-outras-coloracoes-histologicas-e-microbianas/.
        Acesso em: 19 março 2021.
      </p>
    </div>

    <v-divider vertical></v-divider>

    <div id="lado_B">
      <h2>LINHA DO TEMPO</h2>

      <v-timeline>
        <v-timeline-item
          v-for="(year, i) in years"
          :key="i"
          :color="year.color"
          small
        >
          <template v-slot:opposite>
            <span
              :class="`headline font-weight-bold ${year.color}--text`"
              v-text="year.year"
            ></span>
          </template>
          <div class="py-4">
            <!-- <h2 :class="`headline font-weight-light mb-4 ${year.color}--text`">
              {{ year.titulo }}
            </h2> -->
            <div>{{ year.txt }}</div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>

<script>
export default {
  name: "Microscopia",
  data: () => ({
    menu: [
      {
        nome: "Oculares",
        id: "oculares",
        menu: false,
        txt: "Lente que o visualizador examina para ver a amostra. A ocular geralmente contém um aumento de 10x ou 15x.",
      },
      {
        nome: "Macrométrico",
        id: "macrometrico",
        menu: false,
        txt: "Estabelece um foco geral para a amostra analisada.",
      },
      {
        nome: "Micrométrico",
        id: "micrometrico",
        menu: false,
        txt: "Estabelece um foco mais preciso e aumenta o detalhe da amostra analisada.",
      },
      {
        nome: "Braço",
        id: "braco",
        menu: false,
        txt: "Parte que conecta o tubo à base do microscópio.",
      },
      {
        nome: "Pinça",
        id: "pinca",
        menu: false,
        txt: "Parte metálica que mantém a lâmina presa.",
      },
      {
        nome: "Liga/Desliga",
        id: "liga_desliga",
        menu: false,
        txt: "Chave na base do microscópio que liga ou desliga o iluminador.",
      },
      {
        nome: "Tubo Oculares",
        id: "tubo_oculares",
        menu: false,
        txt: "Parte que conecta a ocular às lentes objetivas.",
      },
      {
        nome: "Corpo do Tubo",
        id: "corpo_do_tubo",
        menu: false,
        txt: "?????? ??????? ??????",
      },
      {
        nome: "Revolver",
        id: "revolver",
        menu: false,
        txt: "Torre rotativa que contém as lentes objetivas. O visualizador gira para selecionar diferentes lentes objetivas.",
      },
      {
        nome: "Objetivas",
        id: "objetivas",
        menu: false,
        txt: "Lentes mais importantes de um microscópio óptico, com aumento de 4x até 100x.",
      },
      {
        nome: "Platina",
        id: "platina",
        menu: false,
        txt: "Plataforma plana onde a lâmina é colocada.",
      },
      {
        nome: "Condensador",
        id: "condensador",
        menu: false,
        txt: "Reúne e concentra a luz do iluminador sobre a amostra que está sendo visualizada.",
      },
    ],
    menu2: false,
    years: [
      {
        color: "#00636B",
        year: "0721",
        txt: "21 a.C – Lente de Layard, uma das primeiras lentes criadas.",
      },
      {
        color: "#00636B",
        year: "1280",
        txt: "Invenção dos óculos",
      },
      {
        color: "#00636B",
        year: "1665",
        txt: "Robert Hooke publica o livro Micrographia. O termo célula é usado pela primeira vez.",
      },
      {
        color: "#00636B",
        year: "1675",
        txt: "Anton van Leeuwenhoek aprimora as lentes, sendo o primeiro a observar bactérias.",
      },
      {
        color: "#00636B",
        year: "1830",
        txt: "Joseph Jackson Lister reduz o problema com a aberração esférica. Quando as lentes foram colocadas em distâncias precisas uma da outra proporcionaram uma boa ampliação sem desfocar a imagem.",
      },
      {
        color: "#00636B",
        year: "1878",
        txt: "Ernst Abbe formula uma teoria matemática correlacionando a resolução ao comprimento de onda da luz.",
      },
      {
        color: "#00636B",
        year: "1903",
        txt: "Richard Zsigmondy desenvolve o ultramicroscópio e é capaz de estudar objetos abaixo do comprimento de onda da luz.",
      },
      {
        color: "#00636B",
        year: "1932",
        txt: "Frits Zernike inventa o microscópio de contraste de fase que permite o estudo de materiais biológicos incolores e transparentes.",
      },
      {
        color: "#00636B",
        year: "1933",
        txt: "Ernst Ruska desenvolve o microscópio eletrônico. A capacidade de usar elétrons em microscopia melhora muito a resolução e expande as fronteiras da exploração.",
      },
      {
        color: "#00636B",
        year: "1981",
        txt: "Gerd Binnig e Heinrich Rohrer inventam o microscópio de tunelamento por varredura que fornece imagens tridimensionais de objetos ao nível atômico.",
      },
    ],
  }),
};
</script>

<style scoped>
h1 {
  font-size: 50px;
}
#todo {
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: row;
}

#lado_A {
  width: 50%;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 25px;
  color: #00636b;
  /* background-color: aliceblue; */
}
#lado_B {
  width: 50%;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 25px;
  color: #00636b;
  /* background-color: aquamarine; */
}

#parag_01 {
  width: 100%;
  text-align: justify;
  text-justify: distribute;
}
#parag_02 {
  width: 100%;
  font-size: 8pt;
  text-align: justify;
  text-justify: distribute;
}

#laminas {
  color: #00636b;
}

#microscopioID {
  padding: 5px;
  position: relative;
}

#fonteBT {
  font-size: 12px;
}

#oculares {
  position: absolute;
  top: 4.5%;
  left: 5%;
}

#macrometrico {
  position: absolute;
  top: 23.3%;
  left: 2%;
}

#micrometrico {
  position: absolute;
  top: 35.3%;
  left: 0%;
}

#braco {
  position: absolute;
  top: 50.3%;
  left: 4%;
}

#pinca {
  position: absolute;
  top: 64.3%;
  left: 12%;
}

#liga_desliga {
  position: absolute;
  top: 79%;
  left: -5%;
}

#tubo_oculares {
  position: absolute;
  top: 4.3%;
  left: 79%;
}

#corpo_do_tubo {
  position: absolute;
  top: 15.7%;
  left: 79%;
}

#revolver {
  position: absolute;
  top: 28%;
  left: 79%;
}

#objetivas {
  position: absolute;
  top: 37%;
  left: 79%;
}

#platina {
  position: absolute;
  top: 53.5%;
  left: 79%;
}

#condensador {
  position: absolute;
  top: 62.5%;
  left: 79%;
}

@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 25px;
  }
  #todo {
    flex-direction: column;
    background-size: 100%;
  }
  #lado_A {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #lado_B {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #parag_01 {
    width: 100%;
  }
  #parag_02 {
    width: 100%;
  }
  #microscopioID {
    padding: 45px;
    position: relative;
  }

  #fonteBT {
    font-size: 10px;
  }

  #oculares {
    position: absolute;
    top: 14%;
    left: 7%;
  }

  #macrometrico {
    position: absolute;
    top: 28%;
    left: 3%;
  }

  #micrometrico {
    position: absolute;
    top: 37%;
    left: 1%;
  }

  #braco {
    position: absolute;
    top: 48.3%;
    left: 10%;
  }

  #pinca {
    position: absolute;
    top: 58.8%;
    left: 17%;
  }

  #liga_desliga {
    position: absolute;
    top: 70%;
    left: -1%;
  }

  #tubo_oculares {
    position: absolute;
    top: 14.3%;
    left: 72%;
  }

  #corpo_do_tubo {
    position: absolute;
    top: 22.7%;
    left: 72%;
  }

  #revolver {
    position: absolute;
    top: 32%;
    left: 72%;
  }

  #objetivas {
    position: absolute;
    top: 38.5%;
    left: 72%;
  }

  #platina {
    position: absolute;
    top: 51.5%;
    left: 72%;
  }

  #condensador {
    position: absolute;
    top: 58%;
    left: 72%;
  }
}
</style>
