<template>
  <div id="todo">
    <div id="lado_A">
      <h1>BEM VINDO!</h1>
      <h2>Aqui você encontra estudos sobre</h2>
      <br />

      <v-btn class="btn" color="#009687" dark to="/histologia">
        HISTOLOGIA
      </v-btn>

      <br />
      <br />

      <v-btn class="btn" color="#009687" dark to="/patologia">
        PATOLOGIA
      </v-btn>

      <br />
      <br />
      <br />

      <p id="parag_01">
        Esse site foi desenvolvido pela Faculdade de Medicina da UniRV e esta
        disponível on-line para todos.
      </p>

      <div id="micro">
        <v-img
          alt="Logo HistoPat"
          src="../assets/img/microscopio@2x.png"
          transition="scale-transition"
          width="93%"
        />
        <p>Aprenda mais sobre</p>
        <v-btn class="btn" color="#009687" dark to="/microscopia">
          MICROSCOPIA
        </v-btn>
      </div>
    </div>

    <div id="lado_B">
      <div id="micro_B">
        <v-img
          alt="Logo HistoPat"
          src="../assets/img/logo_color.svg"
          transition="scale-transition"
          width="90%"
        />
      </div>
      <div id="micro_B">
        <div id="medica">
          <div id="m_a">
            <p>Nos ajude respondendo algumas perguntas.</p>
          </div>
          <div id="m_b">
            <v-img
              alt="Logo HistoPat"
              src="../assets/img/medica@2x.png"
              transition="scale-transition"
            />
          </div>
        </div>
        <v-btn text class="btn" color="#009687" to="/patologia" elevation="3">
          RESPONDA NOSSO QUESTIONARIO
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
h1 {
  font-size: 50px;
}
#todo {
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
}

#lado_A {
  width: 50%;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 25px;
  color: #00636b;
}
#lado_B {
  width: 50%;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 55px;
}
.btn {
  width: 100%;
  color: #fff;
}
#parag_01 {
  width: 100%;
  text-align: justify;
  text-justify: inter-word;
}
#micro {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
#micro_B {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
#medica {
  width: 100%;
  margin-top: 2px;
  flex-direction: row;
  display: flex;
}
#m_a {
  width: 50%;
  color: #00636b;
  padding-left: 40px;
  display: flex;
  align-self: flex-end;
  text-align: right;
  text-justify: inter-word;
}
#m_b {
  width: 60%;
  padding-right: 25px;
}

@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 25px;
  }
  #todo {
    flex-direction: column;
  }
  #lado_A {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #lado_B {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .btn {
    width: 100%;
  }
  #parag_01 {
    width: 100%;
  }
  #micro {
    width: 100%;
  }
  #m_a {
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
